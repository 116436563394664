<template>
  <a-drawer
    :title="`片库设置【${data.name}】`"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="false"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 剧名 -->
        <template v-if="column.key === 'remark_name'">
          {{ record.drama.remark_name }}
        </template>
        <!-- 是否可见 -->
        <template v-if="column.key === 'is_show'">
          <a-switch
            v-model:checked="record.agent_drama_mapping.is_show"
            checked-children="开"
            un-checked-children="关"
            @change="changeVisible($event, record)"
          />
        </template>
        <!-- 是否可下载源片 -->
        <template v-if="column.key === 'is_download'">
          <a-switch
            v-model:checked="record.agent_drama_mapping.is_download"
            checked-children="开"
            un-checked-children="关"
            @change="changeDownload($event, record)"
          />
        </template>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { getDramaSettingsList, dramaSetShow, dramaSetDownload } from '@/api/operate'
import Loading from '@/components/Loading'

// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'remark_name',
    key: 'remark_name'
  },
  {
    title: '是否可见',
    dataIndex: 'is_show',
    key: 'is_show'
  },
  {
    title: '是否可下载源片',
    dataIndex: 'is_download',
    key: 'is_download'
  }
])

// 显示
function showDrawer (record) {
  // 清空
  dataSource.value = []
  // 记录
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 切换可见状态
function changeVisible (e, record) {
  const params = {
    project_agent_id: data.value.id,
    project_drama_id: record.id,
    is_show: e ? 1 : 0
  }
  dramaSetShow(params).then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      message.success('设置成功')
    } else {
      message.error(res.message || msg)
      getList()
    }
  }).catch(() => {
    message.error('设置失败')
    getList()
  })
}

// 切换下载可见状态
function changeDownload (e, record) {
  const params = {
    project_agent_id: data.value.id,
    project_drama_id: record.id,
    is_download: e ? 1 : 0
  }
  dramaSetDownload(params).then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      message.success('设置成功')
    } else {
      message.error(res.message || msg)
      getList()
    }
  }).catch(() => {
    message.error('设置失败')
    getList()
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    project_agent_id: data.value.id
    // page: pagination.current,
    // page_size: pagination.pageSize
  }
  getDramaSettingsList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data
      list.forEach(item => {
        item.agent_drama_mapping.is_show = item.agent_drama_mapping.is_show ? true : false
        item.agent_drama_mapping.is_download = item.agent_drama_mapping.is_download ? true : false
      })
      dataSource.value = data
      // pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>