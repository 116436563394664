<template>
  <a-drawer
    :title="`结算单【${data.name}】`"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 操作 -->
      <a-button type="primary" @click="touchAdd"><plus-outlined /> 新建</a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 结算金额 -->
        <template v-if="column.key === 'cash_amount'">
          {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchDetail(record)">结算明细</a>
        </template>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 创建结算单 -->
    <CreateSettlementDrawer ref="RefCreateSettlementDrawer" @success="getList"></CreateSettlementDrawer>
    <!-- 结算单详情 -->
    <SettlementDetailDrawer ref="RefSettlementDetailDrawer"></SettlementDetailDrawer>
  </a-drawer>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { getCashList } from '@/api/operate'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import CreateSettlementDrawer from './CreateSettlementDrawer.vue'
import SettlementDetailDrawer from './SettlementDetailDrawer.vue'

// 数据详情
let RefCreateSettlementDrawer = ref(null)
// 结算单详情
let RefSettlementDetailDrawer = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 数据源
let dataSource = ref([])
// 汇总数据
let summary = ref({})
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '创建日期',
    dataIndex: 'cash_date',
    key: 'cash_date'
  },
  {
    title: '结算天数',
    dataIndex: 'cash_day',
    key: 'cash_day'
  },
  {
    title: '结算金额',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  },
  {
    title: '创建人',
    dataIndex: 'create_username',
    key: 'create_username'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 显示
function showDrawer (record) {
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    project_agent_id: data.value.id,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  getCashList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      // 汇总数据
      summary.value = data.summary
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新建
function touchAdd () {
  RefCreateSettlementDrawer.value.showDrawer(data.value)
}

// 结算明细
function touchDetail (record) {
  RefSettlementDetailDrawer.value.showDrawer(record)
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>